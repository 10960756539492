<template>
  <el-dialog
    custom-class="table-select-dialog"
    title="选择产品信息"
    :visible.sync="visible"
    append-to-body
    destroy-on-close
    width="600px"
    @close="close"
    @closed="closed"
  >
    <dynamic-form
      v-loading="loading"
      ref="dynamic-form"
      :formItems="formItems"
      :model.sync="model"
      :rules="rules"
    ></dynamic-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="confirm" :disabled="loading">
        确定
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import DynamicForm from './DynamicForm.vue';
import api from '../api/OrderManagement';

export default {
  name: 'SelectProductInfo',
  components: {
    DynamicForm,
  },
  props: {},
  data() {
    return {
      visible: false,
      loading: false,
      model: {},
      context: {},
      formItems: [],
    };
  },
  computed: {
    rules() {
      const result = {};
      this.formItems.forEach(({ name }) => {
        result[name] = [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ];
      });
      return result;
    },
  },
  created() {},
  methods: {
    open(context) {
      this.loading = true;
      this.context = context;
      this.visible = true;
      this.getProductInfo();
    },
    close() {
      this.$refs['dynamic-form'].reset();
      this.visible = false;
    },
    closed() {
      this.context = {};
      this.formItems = [];
    },
    getProductInfo() {
      this.loading = true;
      const promise = new Promise((resolve, reject) => {
        const { id } = this.context;
        api
          .getProductOptionList(id)
          .then(res => {
            this.addFormItems(res.result);
            resolve(res);
          })
          .catch(e => {
            reject(e);
          })
          .finally(() => {
            this.loading = false;
          });
      });
      return promise;
    },
    addFormItems(items) {
      if (!items) {
        return false;
      }
      if (items && Array.isArray(items)) {
        const formItems = [];
        items.forEach(({ opsCode, opsValues }, index) => {
          const label = this.valueToName(
            this.filterDictionary('0111'),
            opsCode,
          );
          const formItem = {
            label,
            type: 'select',
            name: `formItem-${index}`,
            placeholder: `请选择${label}`,
            options: opsValues,
          };
          formItems.push(formItem);
        });
        this.formItems = formItems;
      }
      return true;
    },
    confirm() {
      this.$refs['dynamic-form'].validate(() => {
        this.$emit('selected', this.model);
        this.close();
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
