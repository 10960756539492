<template>
  <el-drawer
    custom-class="drawer-submit-form"
    title="添加订单"
    :visible.sync="visible"
    size="100%"
    destroy-on-close
    @close="close"
    @closed="closed"
  >
    <div class="submit-form">
      <el-form ref="form" size="small" label-width="170px" :model="model" :rules="rules">
        <div class="form-block">
          <p class="form-block-title">客户信息</p>
          <el-row>
            <el-col :span="10">
              <el-form-item label="客户姓名" prop="userName">
                <el-input v-model="model.userName" placeholder="请搜索客户姓名" readonly>
                  <el-button
                    type="primary"
                    slot="append"
                    icon="el-icon-search"
                    @click="select('select-name')"
                  >
                  </el-button>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-form-item label="客户手机" prop="userPhone">
                <el-input v-model="model.userPhone" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-form-item label="客户身份证号码" prop="userIdentNo">
                <el-input v-model="model.userIdentNo" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div class="form-block">
          <p class="form-block-title">产品信息</p>
          <el-row>
            <el-col :span="10">
              <el-form-item label="产品机型" prop="productName">
                <el-input v-model="model.productName" placeholder="请搜索产品机型" readonly>
                  <el-button
                    type="primary"
                    slot="append"
                    icon="el-icon-search"
                    @click="select('select-model')"
                  >
                  </el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="产品信息" prop="productInfo">
                <el-input v-model="model.productInfo" placeholder="请搜索产品信息" readonly>
                  <el-button
                    type="primary"
                    slot="append"
                    icon="el-icon-search"
                    @click="
                      select('select-product-info', {
                        id: model.productId,
                      })
                    "
                  >
                  </el-button>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-form-item label="基础估值" prop="basicPrice">
                <el-input v-model="model.basicPrice" disabled> </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="产品序列号" prop="productSerialNumber">
                <el-input v-model="model.productSerialNumber" placeholder="请输入产品序列号">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div class="form-block">
          <p class="form-block-title">初始评估结果</p>
          <el-button type="text" icon="el-icon-right" @click="assessment">
            进入评估页面
          </el-button>
          <el-row>
            <el-col :span="20">
              <el-table
                stripe
                border
                size="mini"
                :header-cell-style="{
                  background: '#f2f2f2',
                }"
                :data="orderQuestionList"
              >
                <el-table-column
                  v-for="(column, m) in columns"
                  :key="`column-${m}`"
                  :header-align="column.headerAlign ? column.headerAlign : 'center'"
                  :align="column.align ? column.align : 'center'"
                  :type="column.type"
                  :label="column.label"
                  :prop="column.prop"
                >
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="10">
              <el-form-item label="初始估值" prop="initAssessment">
                <el-input v-model="model.initAssessment" disabled> </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="远程与客户商品报价（请注意折扣率）" prop="priceQuotation">
                <el-input v-model="model.priceQuotation" placeholder="请输入远程与客户商品报价">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="请上传与客户远程沟通后获取的产品相关图片" prop="attachment-3">
                <el-input v-show="false" v-model="model['attachment-3']"> </el-input>
                <l-upload-img
                  v-if="visible"
                  :folderName="'order'"
                  @getFile="files => _updateFilefield(3, files)"
                ></l-upload-img>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div class="form-block">
          <p class="form-block-title">快递单号信息</p>
          <el-row>
            <el-col :span="10">
              <el-form-item label="是否现场收货" prop="isLive">
                <el-radio-group v-model="model.isLive" @change="value => change('isLive', value)">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="model.isLive === 0">
            <el-col :span="10">
              <el-form-item label="快递单号" prop="courierNumber">
                <el-input v-model="model.courierNumber" placeholder="请输入快递单号"> </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="快递寄出时间" prop="courierSentDate">
                <el-date-picker
                  v-model="model.courierSentDate"
                  type="datetime"
                  value-format="yyyy-MM-ddTHH:mm"
                  placeholder="请选择快递寄出时间"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
    <div class="submit-footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" :loading="submitLoading" @click="submit">
        保存
      </el-button>
    </div>
    <select-name ref="select-name" @selected="selectedName"></select-name>
    <select-model ref="select-model" @selected="selecteModel"></select-model>
    <select-product-info
      ref="select-product-info"
      @selected="selecteProductInfo"
    ></select-product-info>
    <assessment ref="assessment" @submit="assessmentSubmit"></assessment>
  </el-drawer>
</template>
<script>
import LUploadImg from '@/components/LUploadImg.vue';
import SelectName from '../../../components/SelectName.vue';
import SelectModel from '../../../components/SelectModel.vue';
import SelectProductInfo from '../../../components/SelectProductInfo.vue';
import Assessment from './Assessment.vue';
import { mapState } from 'vuex';
import api from '../../../api/OrderManagement';
import updateFilefieldMixin from '../../../mixins/update-filefield-mixin';

export default {
  name: 'AddOrder',
  components: {
    LUploadImg,
    SelectName,
    SelectModel,
    SelectProductInfo,
    Assessment,
  },
  props: {},
  mixins: [updateFilefieldMixin],
  data() {
    return {
      visible: false,
      model: {
        userId: '',
        userName: '',
        userPhone: '',
        userIdentNo: '',

        productId: '',
        firstCateName: '',
        productName: '',
        productInfo: '',
        basicPrice: '',
        productSerialNumber: '',

        assessmentId: '',
        initAssessment: '',
        priceQuotation: '',
        'attachment-3': '',

        isLive: '',
        courierNumber: '',
        courierSentDate: '',
      },
      rules: {
        userId: [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
        userName: [
          {
            required: true,
            message: '请选择',
          },
        ],
        productName: [
          {
            required: true,
            message: '请选择',
          },
        ],
        productInfo: [
          {
            required: true,
            message: '请选择',
          },
        ],
        productSerialNumber: [
          {
            required: true,
            message: '请输入',
          },
        ],
        priceQuotation: [
          {
            required: true,
            message: '请输入',
          },
        ],
        'attachment-3': [
          {
            required: true,
            message: '请添加',
            trigger: 'change',
          },
        ],
        courierNumber: [
          {
            required: true,
            message: '请输入',
          },
        ],
        courierSentDate: [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
        isLive: [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
      },
      mapOfAttachment: {},
      columns: [
        {
          type: 'index',
          label: '序号',
        },
        {
          label: '问题项',
          prop: 'questionDescription',
        },
        {
          label: '选项',
          prop: 'optionDescription',
          headerAlign: 'center',
          align: 'left',
        },
        {
          label: '估值影响',
          prop: 'impactBasePrice',
        },
      ],
      orderQuestionList: [],
      submitLoading: false,
    };
  },
  computed: {
    ...mapState('login', ['userInfo']),
  },
  created() {},
  methods: {
    open() {
      this.visible = true;
    },
    close() {
      this.$refs.form.resetFields();
      this.visible = false;
    },
    closed() {
      this.mapOfAttachment = {};
      this.submitLoading = false;
      this.orderQuestionList = [];

      const target = { ...this.model };
      Object.assign(target, {
        userId: '',
        productId: '',
        assessmentId: '',
      });
      this.model = target;
    },
    select(ref, context) {
      if (ref) {
        if (ref === 'select-product-info') {
          const { productId } = this.model;
          if (!productId) {
            this.$message.warning('请先选择产品机型');
            return false;
          }
        }
        this.$refs[ref].open(context);
      }
      return true;
    },
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const { assessmentId } = this.model;
          if (!assessmentId) {
            this.$message.warning('请先评估');
          } else {
            this.requestApi();
          }
        }
      });
    },

    selectedName(row) {
      const { id, realName, phone, identNo } = row;
      const target = { ...this.model };
      Object.assign(target, {
        userId: id,
        userName: realName || '没有名字的黑户',
        userPhone: phone,
        userIdentNo: identNo || '321323199906060915',
      });
      this.model = target;
    },
    selecteModel({ id, firstCateName, modelName, basicPrice }) {
      const target = { ...this.model };
      Object.assign(target, {
        productId: id,
        firstCateName,
        productName: modelName,
        basicPrice,
      });
      this.model = target;
    },
    selecteProductInfo(info) {
      const productInfo = Object.values(info).join('/');
      const target = { ...this.model };
      Object.assign(target, {
        productInfo,
      });
      this.model = target;
    },
    assessment() {
      const { productId } = this.model;
      if (!productId) {
        this.$message.warning('请先选择产品机型');
        return false;
      }
      return this.$refs.assessment.open(productId);
    },
    assessmentSubmit({ assessmentId, assessmentAmount, orderQuestionList }) {
      const target = { ...this.model };
      Object.assign(target, {
        assessmentId,
        initAssessment: assessmentAmount,
      });
      this.model = target;
      this.orderQuestionList = orderQuestionList;
    },
    requestApi() {
      this.submitLoading = true;
      const promise = new Promise((resolve, reject) => {
        const { model, mapOfAttachment } = this;

        const attachmentInfoList = Object.values(mapOfAttachment).flat();
        const ext = {
          ...model,
          attachmentInfoList,
          creatorId: this.userInfo.userId,
        };
        api
          .addOrderInfo(ext)
          .then(res => {
            this.$message.success('操作成功');
            this.$emit('successCallback');
            this.close();
            resolve(res);
          })
          .catch(e => {
            reject(e);
          })
          .finally(() => {
            this.submitLoading = false;
          });
      });
      return promise;
    },
    change(type) {
      switch (type) {
        case 'isLive':
          {
            const target = { ...this.model };
            Object.assign(target, {
              courierNumber: '',
              courierSentDate: '',
            });
            this.model = target;
          }

          break;

        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
