<template>
  <el-dialog
    custom-class="table-select-dialog"
    title="选择客户"
    :visible.sync="visible"
    append-to-body
    destroy-on-close
    @opened="getList"
    @closed="closed"
  >
    <dynamic-form
      :config="{
        labelWidth: '110px',
      }"
      :formItems="formItems"
      :model.sync="model"
    ></dynamic-form>
    <search-reset
      :loading="loading"
      :model.sync="model"
      @search="getList"
    ></search-reset>
    <dynamic-table
      :config="config"
      :loading="loading"
      :columns="columns"
      :tableData="tableData"
      :selectRow.sync="selectRow"
      :pagination.sync="pagination"
      @update="getList"
    ></dynamic-table>
    <span slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="confirm" :disabled="!selectRow">
        确定
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import DynamicForm from './DynamicForm.vue';
import SearchReset from './SearchReset.vue';
import DynamicTable from './DynamicTable.vue';
import api from '../api/UserListApi';

export default {
  name: 'SelectName',
  components: {
    DynamicForm,
    SearchReset,
    DynamicTable,
  },
  props: {},
  data() {
    return {
      visible: false,
      model: {},
      formItems: [
        {
          label: '客户姓名',
          type: 'text',
          name: 'realName',
          placeholder: '请输入客户姓名',
        },
        {
          label: '客户联系方式',
          type: 'text',
          name: 'phone',
          placeholder: '请输入客户联系方式',
        },
      ],
      loading: false,
      config: {
        stripe: false,
        highlightCurrentRow: true,
      },
      columns: [
        {
          type: 'index',
          label: '序号',
        },
        {
          label: '客户ID',
          prop: 'id',
        },
        {
          label: '客户姓名',
          prop: 'realName',
        },
        {
          label: '客户联系方式',
          prop: 'phone',
        },
        {
          label: '客户注册时间',
          prop: 'regTime',
        },
        {
          label: '是否绑定手机',
          prop: 'phone',
          formatter(row, column, cellValue) {
            return cellValue ? '是' : '否';
          },
        },
        {
          label: '是否实名认证',
          prop: 'identNo',
          formatter(row, column, cellValue) {
            return cellValue ? '是' : '否';
          },
        },
        {
          label: '身份证号码',
          prop: 'identNo',
        },
        {
          label: '银行账号',
          prop: 'bankCard',
        },
      ],
      tableData: [],
      pagination: {
        total: 0,
        pageSize: 30,
        currentPage: 1,
      },
      selectRow: null,
    };
  },
  computed: {},
  created() {},
  methods: {
    open() {
      this.loading = true;
      this.visible = true;
    },
    close() {
      this.visible = false;
    },
    closed() {
      this.model = {};
      this.tableData = [];
      this.selectRow = null;
      this.pagination = {
        total: 0,
        pageSize: 30,
        currentPage: 1,
      };
      this.loading = false;
    },
    getList() {
      this.loading = true;
      const promise = new Promise((resolve, reject) => {
        const { model, pagination } = this;
        const { currentPage, pageSize } = pagination;
        const ext = {
          pageNum: currentPage,
          pageSize,
          ...model,
          isRealName: 1,
        };
        api
          .getUserInfoList(ext)
          .then(res => {
            const { datas, totalRecords } = res.result;
            this.tableData = datas;
            this.pagination.total = totalRecords;
            resolve(res);
          })
          .catch(e => {
            reject(e);
          })
          .finally(() => {
            this.loading = false;
          });
      });
      return promise;
    },
    confirm() {
      if (!this.selectRow) {
        this.$message.warning('请选择');
        return false;
      }
      this.$emit('selected', this.selectRow);
      return this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
