<template>
  <div>
    <l-toggle>
      <dynamic-form :formItems="formItems" :model.sync="model"></dynamic-form>
      <search-reset
        :loading="loading"
        :model.sync="model"
        @search="
          _clearPagination();
          getList();
        "
      ></search-reset>
    </l-toggle>
    <dynamic-table
      :loading="loading"
      :columns="columns"
      :tableData="tableData"
      :pagination.sync="pagination"
      :selections.sync="selections"
      :tools="tools"
      @update="getList"
    ></dynamic-table>
    <add-order ref="add-order" @successCallback="getList"></add-order>
  </div>
</template>

<script>
import LToggle from '../../../components/LToggle.vue';
import DynamicForm from '../../../components/DynamicForm.vue';
import SearchReset from '../../../components/SearchReset.vue';
import DynamicTable from '../../../components/DynamicTable.vue';
import AddOrder from './AddOrder.vue';
import baseMixin from '../../../mixins/base-mixin';
import api from '../../../api/OrderManagement';
import { mapState } from 'vuex';

export default {
  name: '',
  components: {
    LToggle,
    DynamicForm,
    SearchReset,
    DynamicTable,
    AddOrder,
  },
  mixins: [baseMixin],
  data() {
    return {
      model: {},
      columns: [
        {
          type: 'selection',
          fixed: 'left',
        },
        {
          label: '订单编号',
          prop: 'orderNumber',
          minWidth: '70',
        },
        {
          label: '订单标题',
          prop: 'orderTitle',
          minWidth: '110',
        },
        {
          label: '客户姓名',
          prop: 'userName',
          minWidth: '40',
        },
        {
          label: '客户联系方式',
          prop: 'userPhone',
          minWidth: '40',
        },
        {
          label: '订单开始时间',
          prop: 'orderStartTime',
          minWidth: '60',
        },
        {
          label: '创建人',
          prop: 'creatorName',
          minWidth: '40',
        },
        {
          label: '检测师',
          prop: 'detectionName',
          minWidth: '40',
        },
        {
          label: '当前状态',
          prop: 'orderStatusName',
          minWidth: '80',
        },
        {
          label: '估值金额',
          prop: 'newestAssessmentPrice',
          minWidth: '40',
        },
        {
          label: '支付金额',
          prop: 'paymentAmount',
          minWidth: '40',
        },
        {
          label: '订单更新时间',
          prop: 'updateTime',
          minWidth: '60',
        },
        {
          label: '操作',
          prop: '_action',
          fixed: 'right',
          minWidth: '80',
          actions: [
            {
              title: '提前赎回',
              type: 'text',
              icon: 'el-icon-s-claim',
              condition: 'row.orderStatus==202 || row.orderStatus==403',
              event: this.apply,
            },
            {
              title: '查看',
              type: 'text',
              icon: 'el-icon-view',
              id: 'see',
              condition: '!!row.id',
            },
            {
              title: '删除',
              type: 'text',
              icon: 'el-icon-delete',
              event: this.del,
            },
          ],
        },
      ],
      tableData: [],
      loading: false,
      pagination: {
        total: 0,
        pageSize: 30,
        currentPage: 1,
      },
      selections: [],
      tools: [
        {
          title: '添加订单',
          type: 'primary',
          icon: 'el-icon-plus',
          event: this.add,
        },
        {
          title: '删除选择',
          type: 'danger',
          icon: 'el-icon-delete',
          event: this._deleteSelected,
        },
      ],
    };
  },
  computed: {
    ...mapState('login', ['userInfo']),
    orderStatusOptions() {
      let options = this.filterDictionary('0108');
      const { orderPhase } = this.model;
      if (orderPhase) {
        options = options.filter(({ value }) =>
          `${value}`.startsWith(`${orderPhase}`),
        );
      }
      return options;
    },
    formItems() {
      return [
        {
          label: '订单编号',
          type: 'text',
          name: 'orderNumber',
          placeholder: '请输入订单编号',
        },
        {
          label: '客户姓名',
          type: 'text',
          name: 'userName',
          placeholder: '请输入客户姓名',
        },
        {
          label: '客户联系方式',
          type: 'text',
          name: 'userPhone',
          placeholder: '请输入客户联系方式',
        },
        {
          label: '订单开始时间',
          type: 'daterange',
          starName: 'orderStartTimeBegin',
          starPlaceholder: '请输入开始日期',
          endName: 'orderStartTimeEnd',
          endPlaceholder: '请输入结束日期',
        },
        {
          label: '订单类型',
          type: 'select',
          name: 'orderPhase',
          placeholder: '请输入订单类型',
          options: this.filterDictionary('0107'),
          change: () => {
            const target = { ...this.model };
            Object.assign(target, {
              orderStatus: '',
            });
            this.model = target;
          },
        },
        {
          label: '订单状态',
          type: 'select',
          name: 'orderStatus',
          placeholder: '请输入订单状态',
          options: this.orderStatusOptions,
        },
      ];
    },
  },
  created() {},
  methods: {
    getList() {
      this.loading = true;
      const promise = new Promise((resolve, reject) => {
        const { model, pagination } = this;
        const { currentPage, pageSize } = pagination;
        const ext = {
          pageNum: currentPage,
          pageSize,
          ...model,
        };
        api
          .getOrderInfoList(ext)
          .then(res => {
            const { datas, totalRecords } = res.result;
            this.tableData = datas;
            this.pagination.total = totalRecords;
            resolve(res);
          })
          .catch(e => {
            reject(e);
          })
          .finally(() => {
            this.loading = false;
          });
      });
      return promise;
    },
    deleteOrderInfo(ids) {
      const promise = new Promise((resolve, reject) => {
        const ext = {
          orderIdList: ids,
        };
        api
          .deleteOrderInfo(ext)
          .then(res => {
            this.getList();
            resolve(res);
          })
          .catch(e => {
            reject(e);
          })
          .finally(() => {});
      });
      return promise;
    },

    del({ id }) {
      this.$confirm('确定删除该条数据吗?', '提示', {
        type: 'warning',
        showClose: false,
        closeOnClickModal: false,
        confirmButtonText: '确定',
        cancelButtonText: '取消',

        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            this.deleteOrderInfo([id]).finally(() => {
              done();
              setTimeout(() => {
                instance.confirmButtonLoading = false;
              }, 300);
            });
          } else {
            done();
          }
        },
      })
        .then(() => {
          this.$message.success('操作成功');
        })
        .catch(() => {});
    },
    add() {
      this.$refs['add-order'].open();
    },
    applyRedeem(orderId) {
      console.info(orderId);
      const promise = new Promise((resolve, reject) => {
        const ext = {
          orderId,
          applicantId: this.userInfo.userId,
          applicantName: this.userInfo.userName,
        };
        api
          .applyRedeem(ext)
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            reject(e);
          })
          .finally(() => {});
      });
      return promise;
    },
    apply({ id }) {
      this.$confirm('你确定提前赎回吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            this.applyRedeem(id)
              .then(() => {
                done();
              })
              .finally(() => {
                instance.confirmButtonLoading = false;
              });
          } else {
            done();
          }
        },
      })
        .then(() => {
          this.$message.success('申请成功');
          this.getList();
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss"></style>
